.App {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100dvw;
  height: 100dvh;
  overflow-y: auto;
  flex-direction: column;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

html body {
  color: white;
  min-height: 100dvh;
  background-color: #1b2331;
  font-family: sans-serif;
  overflow-y: auto;
  display: flex;
  scroll-behavior: smooth;
}

body::-webkit-scrollbar {
  display: none!important;
}


@font-face {
  font-family: "OliverRegular";
  src: local("OliverRegular"),
  url("assets/fonts/Oliver/Oliver-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "PoppinsBlack";
  src: local("PoppinsBlack"),
  url("assets/fonts/Poppins/Poppins-Black.otf") format("truetype");
}

@font-face {
  font-family: "PoppinsBold";
  src: local("PoppinsBold"),
  url("assets/fonts/Poppins/Poppins-Bold.otf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "PoppinsLight";
  src: local("PoppinsLight"),
  url("assets/fonts/Poppins/Poppins-Light.otf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "PoppinsRegular";
  src: local("PoppinsRegular"),
  url("assets/fonts/Poppins/Poppins-Regular.otf") format("truetype");
}

@font-face {
  font-family: "RestoraBold";
  src: local("RestoraBold"),
  url("assets/fonts/Restora/RestoraBold.otf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "Restora";
  src: local("Restora"),
  url("assets/fonts/Restora/Restora.otf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "RestoraItalic";
  src: local("RestoraItalic"),
  url("assets/fonts/Restora/RestoraItalic.otf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "ProdaSansMedium";
  src: local("ProdaSansMedium"),
  url("assets/fonts/ProdaSans/ProdaSans-Medium.otf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "ProdaSansItalic";
  src: local("ProdaSansItalic"),
  url("assets/fonts/ProdaSans/ProdaSans-Italic.otf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "ProdaSansSemiboldItalic";
  src: local("ProdaSansSemiboldItalic"),
  url("assets/fonts/ProdaSans/ProdaSans-SemiBoldItalic.otf") format("truetype");
  font-weight: normal;
}

.oliver-regular {
  font-family: "OliverRegular"!important;
}

.proda-sans-medium {
  font-family: "ProdaSansMedium"!important;
}

.proda-sans-italic {
  font-family: "ProdaSansItalic"!important;
}

.font-poppins-bold {
  font-family: "PoppinsBold"!important;
}

.font-poppins-black {
  font-family: "PoppinsBlack"!important;
}

.font-poppins-regular{
  font-family: "PoppinsLight"!important;
}

.font-restora-italic {
  font-family: "RestoraItalic";
}

.font-poppins-light {
  font-family: "PoppinsLight"!important;
}

.font-restora-bold {
  font-family: "RestoraBold";
}

.font-restora {
  font-family: "Restora";
  letter-spacing: -0.05em;
}

button {
  background-color: #1b2331;
  color: white;
  padding: 10px 20px;
  border-radius: 1px;
  width: 180px;
  appearance: none;
  border: none;
  font-size: 1.3rem;
  box-shadow: 0px 8px 28px -6px rgba(24, 39, 75, 0.12),
    0px 18px 88px -4px rgba(24, 39, 75, 0.14);
  transition: all ease-in 0.1s;
  cursor: pointer;
  opacity: 0.9;
  margin: 20px 0;
  font-family: "PoppinsRegular"!important;
}

.buttonBorderOrange {
  font-size: 15px!important;
  border: 3px solid #FE6D00!important;
}

.buttonBorderPurple {
  font-size: 15px!important;
  border: 3px solid #7D275A!important;
}

.buttonBorderYellow {
  font-size: 15px!important;
  border: 3px solid #FFBE3B!important;
}

.buttonBorderLightPink {
  font-size: 15px!important;
  border: 3px solid #E5938F!important;
}

.buttonBorderLightBlue {
  font-size: 15px!important;
  border: 3px solid #74A3B8!important;
}

.buttonBorderWhite {
  font-size: 15px!important;
  border: 3px solid #ffffff !important;
}

.buttonOrangeBorderWhite {
  width: 130px!important;
  background-color: #FE6D00!important;
  font-size: 15px!important;
  border: 3px solid #ffffff !important;
}

.buttonOrangeBorderWhite:hover {
  background-color: white!important;
  font-size: 15px!important;
  color: #FE6D00;
}

.buttonPurpleBorderWhite {
  width: 130px!important;
  background-color: #7D275A!important;
  font-size: 15px!important;
  border: 3px solid #ffffff !important;
}

.buttonPurpleBorderWhite:hover {
  background-color: white!important;
  font-size: 15px!important;
  color: #7D275A;
}

.buttonTransparentBorderOrange {
  background-color: transparent!important;
  font-size: 15px!important;
  color: #FE6D00;
  border: 3px solid #FE6D00 !important;
}

.buttonTransparentBorderYellow {
  background-color: transparent!important;
  font-size: 15px!important;
  padding-top: 14px;
  color: #FFBE3C;
  border: 3px solid #FFBE3C !important;
}
